import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './Dialog.module.scss';

// components
import dynamic from 'next/dynamic';
const Promo = dynamic(() => import("./Promo"));
const Response = dynamic(() => import("./Response"));

const PromoDIalog = (props) => {
  const [submissionState, setSubmissionState] = useState(false);
  const [response, setResponse] = useState();

  const modalContentDisplay = () => {
    if (!submissionState) {
      return (<Promo content={props.content} handleClose={props.handleClose} setSubmissionState={setSubmissionState} setResponse={setResponse} />);
    } else {
      return (<Response handleClose={props.handleResClose} response={response} />);
    }
  }

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        aria-labelledby="promo-dialog"
        dialogClassName={styles.modalContainer}
        backdrop="false"
        backdropClassName="globalModalBackdrop"
      >
        <Modal.Body className={styles.modalBody}>
          <button className={`${styles.closeModal} closeModal`} type="button" onClick={props.handleClose}>+</button>
          {modalContentDisplay()}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PromoDIalog;